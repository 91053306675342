import React from 'react';
import Partenaire from './partenaire';

const PartenaireList = () => {
    const partners = [
        {
            name: "OBIDON INTERNATIONAL",
            logo: "./assets/obi.png",
            contact: "(+229) 91 51 83 83",
            description: "Obidon International est une suite logicielle de gestion en ligne qui accompagne les entreprises dans leur gestion courante.",
        },
        {
            name: "OBIDON TRAVEL",
            logo: "./assets/obi.png",
            address: "12 Rue de la Paix, Cotonou, Bénin",
            contact: "(+229) 91 51 83 83",
            description: "Obidon Travel est un agence de voyage dont le cœur de métier est de rendre des séjours inoubliables en vous accompagnant dans l'organisation intégrale de votre voyage à travers le monde.",
        },
        {
            name: "OBIDON LOGISTIQUE",
            logo: "./assets/obi.png",
            address: "Adresse 3, Ville, Pays",
            contact: "(+229) 91 51 83 83",
            description: "Obidon logistique gère la location de voiture pour tous vos évènements.",
        },
        {
            name: "REIMOKO ASSUR",
            logo: "./assets/11.png",
            contact: "(+229)  68 90 77 90",
            description: "Reimoko Assur est une société de courtage en Assurance.",
        },
        {
            name: " MJ-C (Milliardaire en Jesus- Christ)",
            logo: "./assets/13.png",
            address: "Adresse 5, Ville, Pays",
            contact: "(+229) 96 19 82 12, Cotonou Bénin",
            description: "Event, Book évent, planer Event, weeding planer, conseils.",
        },
    
        {
            name: "12 Avenue de la mode",
            logo: "./assets/07.png",
            address: "Adresse 7, Ville, Pays",
            contact: "(+229) 97 72 43 35, Cotonou Bénin",
            description: "Confection de chemise WOODIN, UNIWAX, et chemise classique.",
        },
        {
            name: "Atelier Boss Art",
            logo: "./assets/12.png",
            address: "Adresse 8, Ville, Pays",
            contact: "(+33) 7 51  48 06 26 , Cotonou Bénin, West Africa",
            description: "ATELIER BOSS ART est votre partenaire idéal pour la production audiovisuelle et la communication",
        },
        {
            name: "Le Baratie",
            logo: "./assets/08.png",
            address: "Adresse 9, Ville, Pays",
            contact: "(+229) 66 35 93 92, Cotonou Bénin",
            description: "Création des saveurs personnalisés pour vos célébrations.",
        },
        
        {
            name: "Angélus Luxieux",
            logo: "./assets/14.png",
            address: "Adresse 10, Ville, Pays",
            contact: "(+229) 96 31 39 70, Cotonou Bénin",
            description: "Vetement sur mesure, Démi-mesure, Sartorialiste, Menswear.",
        }
    ];
    

    return (
        <div style={{backgroundImage: `url('./assets/01.jpg')`,backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'center',
            // height: '100vh',
            position: 'relative',}}>
            {partners.map((partner, index) => (
                <Partenaire key={index} partner={partner} />
            ))}
        </div>
    );
};

export default PartenaireList;
