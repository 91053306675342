import React, { useState } from 'react';
import { Button, Form, Row, Col, FormGroup, Table, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmPresence = () => {
  const [modal, setModal] = useState(false);
  const [programModal, setProgramModal] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/partenaire');
  };


  const [userDetails, setUserDetails] = useState({
    nom: '',
    rappel: false,
    username: '',
  });
  const [programUsername, setProgramUsername] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [programDetails, setProgramDetails] = useState({});


  const toggleModal = () => setModal(!modal);
  const toggleProgramModal = () => setProgramModal(!programModal);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async () => {
    const newErrors = {};

    if (!userDetails.nom) {
      newErrors.nom = 'Ce champ est requis';
    }

    if (!userDetails.username) {
      newErrors.username = 'Ce champ est requis';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await axios.post(
          'https://apievent.obidoninternational.com/createparticipant',
          userDetails
        );

        // Check for duplicate username status
        if (response.status === 201) {
          // Assuming 201 means duplicate username for your case
          setErrors({ username: 'Ce nom d’utilisateur existe déjà' });
        } else {
          toast.success("Présence confirmée avec succès");
          setFormVisible(false);
          toggleModal();
          setButtonsVisible(false);
          setUserDetails({ nom: '', rappel: false, username: '' });
          setConfirmationMessage(
            `Mme/Mr/Couple ${userDetails.nom},  le Couple Michèle & Fernando vous remercie pour cette confirmation et sont très heureux de vous compter parmi leurs illustres invités. Votre identifiant vous servira d’accès aux informations telles que le chronogramme des activités et le lieu. Nous vous rappelons que cette cérémonie débutera rigoureusement à 14h heure locale(GMT+1).
            Votre identifiant est [${userDetails.username}]`
          );
        }
      } catch (error) {
        console.error(error);
        toast.error("Une erreur s'est produite");
      }
    }
  };



  const handleProgramUsernameChange = (e) => {
    setProgramUsername(e.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Nettoyage de l'effet lorsqu'il est démonté
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const verifyUsername = async () => {
    const newErrors = {};

    if (!programUsername) {
      newErrors.programUsername = 'Ce champ est requis';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await axios.post('https://apievent.obidoninternational.com/oneinvite', { username: programUsername });

        if (response.status === 201) {
          toast.success("Utilisateur correspondant");
          setIsVerified(true);

          setButtonsVisible(false);

          const userData = response.data;
          setProgramDetails(userData);

          const programResponse = await axios.get('https://apievent.obidoninternational.com/allprogrammes');
          const programData = programResponse.data;

          setProgramDetails({
            ...userData,
            programData: programData
          });

          toggleProgramModal();
        } else {
          toast.error(response.data);
        }
      } catch (error) {
        console.error(error);
        toast.error("Veuillez attendre la validation de votre participation pour avoir accès au programme");
      }
    }
  };





  const styles = {
    container: {
      backgroundImage: `url('./assets/01.jpg')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // S'étend en fonction du contenu
      position: 'relative',
      padding: '20px',
    },
    topLeft: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: windowWidth < 768 ? '150px' : '300px', // Adapte la taille en fonction de la largeur de la fenêtre
      height: 'auto',
    },
    bottomRight: {
      position: 'absolute', // Reste toujours en bas à droite
      bottom: 0,
      right: 0,
      width: windowWidth < 768 ? '150px' : '300px',
      height: 'auto',
    },
    confirmationMessage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/bgcon.jpeg')`,
      padding: '20px',
      textAlign: 'center',
      fontFamily: 'Times New Roman',
    },
    programContainer: {
      marginTop: '50px',
      width: windowWidth < 768 ? '90%' : '60%', // Réduire la largeur sur les petits écrans
      backgroundColor: 'white',
      padding: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <div>
        <img src='./assets/02.png' alt='Top Left' style={styles.topLeft} />
        <img src='./assets/03.png' alt='Bottom Right' style={styles.bottomRight} />
      </div>
      <ToastContainer closeButton={false} position="top-center" />
      {buttonsVisible && (
        <div className="row d-flex justify-content-center">
          <div className="col-sm-12 col-md-4 mt-5 d-flex justify-content-center">
            <Button className='text-white btn' onClick={toggleModal} style={{ backgroundColor: '#ab761e', whiteSpace: 'nowrap', border: 'none', borderRadius: '5px', width: windowWidth < 768 ? '70%' : 'auto' }}>
              Confirmez ma présence
            </Button>
          </div>
          <div className="col-sm-12 col-md-4 mt-5 d-flex justify-content-center">
            <Button className='text-white' onClick={toggleProgramModal} style={{ backgroundColor: '#ab761e', border: 'none', borderRadius: '5px', width: windowWidth < 768 ? '70%' : 'auto' }}>
              Voir le programme
            </Button>
          </div>
          <div className="col-sm-12 col-md-4 mt-5 d-flex justify-content-center">
            <Button className='text-white' onClick={handleButtonClick} style={{ backgroundColor: '#ab761e', border: 'none', borderRadius: '5px', width: windowWidth < 768 ? '70%' : 'auto' }}>
              Voir nos partenaires
            </Button>
          </div>
        </div>
      )}
      {formVisible ? (
        <>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Confirmation de présence</ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="nom">Nom et Prénoms <span className="text-danger">*</span></Label>
                  <Input
                    type="text"
                    name="nom"
                    id="nom"
                    className="border-secondary"
                    value={userDetails.nom}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.nom && <div className="text-danger">{errors.nom}</div>}
                </FormGroup>

                <FormGroup>
                  <Label for="username">Veuillez créer ou définir votre identifiant <span className="text-danger">*</span></Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    className="border-secondary"
                    value={userDetails.username}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.username && <div className="text-danger">{errors.username}</div>}
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" className="border-secondary" name="rappel" checked={userDetails.rappel} onChange={handleInputChange} />{' '}
                    Rappel Google Calendrier
                  </Label>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={handleSubmit}>Confirmer</Button>
              <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={toggleModal}>Annuler</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={programModal} toggle={toggleProgramModal}>
            <ModalBody>
              {!isVerified ? (
                <>
                  <FormGroup>
                    <Label for="programUsername">Entrez votre identifiant</Label>
                    <Input
                      type="text"
                      name="programUsername"
                      id="programUsername"
                      className="border-secondary"
                      value={programUsername}
                      onChange={handleProgramUsernameChange}
                    />
                    {errors.programUsername && <div className="text-danger">{errors.programUsername}</div>}
                  </FormGroup>

                  <Button className='text-xhite' onClick={verifyUsername} style={{ backgroundColor: '#ab761e', whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }}>
                    Accédez au programme
                  </Button>
                </>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} className='text-xhite' onClick={toggleProgramModal}>Fermer</Button>
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <div className='text-white' style={styles.confirmationMessage}>
          {confirmationMessage}
        </div>
      )}
      {isVerified && (
        <div style={styles.programContainer}>
          <h2>Bienvenue {programDetails.nom}</h2>
          <p>Vous êtes à la table {programDetails.Table}</p>
          <h2>Détails du programme</h2>
          {programDetails.programData && programDetails.programData.length > 0 ? (
            <Table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th className='text-white' style={{backgroundColor: '#ab761e'}}>Libellé</th>
                  <th className='text-white' style={{backgroundColor: '#ab761e'}}>Heure de début</th>
                  <th className='text-white' style={{backgroundColor: '#ab761e'}}>Heure de fin</th>
                </tr>
              </thead>
              <tbody>
                {programDetails.programData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.libelle}</td> {/* Assure-toi que l'attribut "libelle" existe dans l'objet */}
                    <td>{item.heure_debut}</td>
                    <td>{item.heure_fin}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Aucun programme disponible pour l'instant.</p>
          )}

          <h2>Découvrez la localisation de l'évènement</h2>
          <div className="container">
            <div className="row">
              <div className="col">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3965.2935860423086!2d2.3991260749914796!3d6.35602999363392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNsKwMjEnMjEuNyJOIDLCsDI0JzA2LjEiRQ!5e0!3m2!1sfr!2sbj!4v1723634930153!5m2!1sfr!2sbj&marker=6.35602999363392,2.3991260749914796"
                  width="100%"
                  height="100%"
                  style={{ border: '0' }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default ConfirmPresence;
