
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/login';
import ConfirmPresence from './pages/ConfirmPresence';
import ProgramPage from './pages/programme';
import PartenaireList from './pages/PartenaireList';


const App = () => {
  return (
    <Router>
        <Routes>
          <Route path="/login" element={<Login/>} />
          <Route path="/" element={<ConfirmPresence/>} />
          <Route path="/programme" element={<ProgramPage/>} />
          <Route path="/partenaire" element={<PartenaireList/>} />
        </Routes>
    </Router>
  );
}

export default App;