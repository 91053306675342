import React from 'react';

const Partenaire = ({ partner }) => {
    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '600px',
            margin: '20px auto',
            padding: '20px',
            backgroundColor: '#ffffff',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
            borderRadius :"20px"
        },
        logoContainer: {
            width: '100px', // Taille fixe pour le conteneur du logo
            height: '100px', // Taille fixe pour le conteneur du logo
            borderRadius: '50%',
            backgroundColor: '#eaeaea',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',

            marginRight: '20px',
            display: 'flex',
            borderColor :'black',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        },
        logo: {
            width: '120px', // Taille fixe pour le logo
            height: '120px', // Taille fixe pour le logo
            objectFit: 'contain', // Utilisez 'contain' pour s'assurer que le logo est entièrement visible
            boxShadow: '0px 4px 10px black',
        },
        info: {
            h1: {
                fontSize: '20px',
                marginBottom: '10px',
            },
            p: {
                fontSize: '14px',
                lineHeight: '1.6',
                marginBottom: '10px',
            },
            link: {
                color: '#1a73e8',
                textDecoration: 'none',
            }
        },

        info: {
            h1: {
                fontSize: '18px',
            },
            p: {
                fontSize: '13px',
            },
            h : {
                color :'blue',
                fontSize: '14px',
            }
        }
    };

    return (
        <div className='container' style={styles.container}>
            <div className="row">
                <div className='col-4' style={styles.logoContainer}>
                    <img src={partner.logo} alt={`${partner.name} Logo`} style={styles.logo} />
                </div>
                <div className='col-8'>
                    <h1 style={styles.info.h1}>{partner.name}</h1>
                    <p style={styles.info.p}>{partner.description}</p>
                    <p style={styles.info.h} ><strong>Contact:</strong> {partner.contact}</p>
                </div>
            </div>
        </div>
    );
};


export default Partenaire;
