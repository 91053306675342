import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Tous les champs doivent être remplis');
    } else {
      try {
        setError('');
        const response = await axios.post('https://apievent.obidoninternational.com/login', { email, password });
        
        console.log(response); // Log the response for debugging
        
        if (response.status === 200 && response.data === "Authentification réussie") {
          toast.success('Connexion réussie!');
          localStorage.setItem('authToken', response.data.token); // Assure-toi que l'API renvoie un token
          navigate('/programme');
        } else {
          toast.error('Erreur de connexion : identifiants incorrects');
        }
      } catch (err) {
        console.error(err); // Log the error for debugging
        toast.error("Erreur de connexion");
      }
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      position: 'relative',
      padding: '20px'
    },
    backgroundImg: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      objectFit: 'cover'
    },
    topLeft: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: windowWidth < 768 ? '150px' : '300px',
      height: 'auto',
    },
    bottomRight: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: windowWidth < 768 ? '150px' : '300px',
      height: 'auto',
    },
    contentContainer: {
      backgroundColor: '#FFFFFF',
      padding: '30px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      textAlign: 'center',
      maxWidth: '400px',
      width: '100%'
    },
    form: {
      width: '100%'
    },
    formGroup: {
      marginBottom: '20px',
      textAlign: 'left'
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      color: '#4A4A4A'
    },
    required: {
      color: 'red'
    },
    input: {
      width: '100%',
      padding: '10px',
      border: '1px solid #CCCCCC',
    },
    button: {
      width: '100%',
      padding: '10px',
      backgroundColor: '#ab761e',
      color: '#FFFFFF',
      border: 'none',
      cursor: 'pointer',
      fontSize: '16px'
    },
    error: {
      color: 'red',
      fontSize: '14px',
      marginBottom: '20px'
    }
  };

  return (
    <div style={styles.container}>
      <ToastContainer closeButton={false} position="top-center" />
      <img src="./assets/01.jpg" alt="Background" style={styles.backgroundImg} />
      <img src="./assets/02.png" alt="Top Left" style={styles.topLeft} />
      <img src="./assets/03.png" alt="Bottom Right" style={styles.bottomRight} />
      <div style={styles.contentContainer}>
        <form style={styles.form} onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label htmlFor="email" style={styles.label}>Email <span style={styles.required}>*</span></label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              placeholder="Entrez votre e-mail" 
              style={styles.input} 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="password" style={styles.label}>Password <span style={styles.required}>*</span></label>
            <input 
              type="password" 
              id="password" 
              name="password" 
              placeholder="Entrer Password" 
              style={styles.input} 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          {error && <p style={styles.error}>{error}</p>}
          <button type="submit" style={styles.button}>Se connecter</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
