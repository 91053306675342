import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, Row, Col, ModalFooter } from 'reactstrap';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { UilPen } from '@iconscout/react-unicons';
import { UilTrashAlt } from '@iconscout/react-unicons';
import { useNavigate } from 'react-router-dom';



const ProgramPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login'); // Redirige vers la page de login si l'utilisateur n'est pas authentifié
    }
  }, [navigate]);
  const participantListRef = useRef(null);
  const [programData, setProgramData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editTable, setEditTable] = useState(null);
  const [editProgram, setEditProgram] = useState(null);
  const [programErrors, setProgramErrors] = useState({});
  const [tableErrors, setTableErrors] = useState({});
  const [selectedTableForPrint, setSelectedTableForPrint] = useState('');
  const [guestsForSelectedTable, setGuestsForSelectedTable] = useState([]);
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [guestsModalVisible, setGuestsModalVisible] = useState(false);
  const guestsModalRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Nettoyage de l'effet lorsqu'il est démonté
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Styles en ligne
  const tableStyles = {
    width: '100%',
    tableLayout: 'fixed', // Force les cellules à partager également l'espace disponible
  };

  const cellStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: windowWidth < 576 ? 'normal' : 'nowrap', // WhiteSpace conditionnel selon la taille de l'écran
  };

  const headerStyle = {
    backgroundColor: 'blue',
    color: 'white',
  };

  const buttonStyles = {
    backgroundColor: "#ab761e",
    whiteSpace: 'nowrap',
    border: 'none',
    borderRadius: '0',
    width: windowWidth < 576 ? '50%' : 'auto',
    transition: 'background-color 0.3s ease',  // Transition pour un effet plus fluide
    cursor: 'pointer',
  };

  const handleButtonClick = (action) => {
    // Logique pour assurer un clic plus fluide
    if (typeof action === 'function') {
      action();
    }
  };



  const [inviteData, setInviteData] = useState([]);
  const [participantData, setParticipantData] = useState([]);
  const [newProgram, setNewProgram] = useState({ libelle: '', heure_debut: '', heure_fin: '', localisation: '' });
  const [newTable, setNewTable] = useState({ nom: '', nombre_place: '' });
  const [tables, setTables] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [tableFormVisible, setTableFormVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [selectedInvite, setSelectedInvite] = useState(null);
  const [selectedTable, setSelectedTable] = useState('');
  const [noGuestsModalVisible, setNoGuestsModalVisible] = useState(false);
  const [newInvite, setNewInvite] = useState({nom: '', Username: '', Table: '', Nombre_place: ''})
  const handlePrintGuestsModal = () => {
    const printContents = guestsModalRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    // window.print();
    document.body.innerHTML = originalContents;
  };


  // const accessToken = JSON.parse(localStorage.getItem("authUser"))?.data?.accessToken;

  const toggleModal = () => setModal(!modal);

  const validateProgramForm = () => {
    const errors = {};

    if (!newProgram.libelle) errors.libelle = "Le nom du programme est requis.";
    if (!newProgram.heure_debut) errors.heure_debut = "L'heure de début est requise.";
    if (!newProgram.heure_fin) errors.heure_fin = "L'heure de fin est requise.";

    setProgramErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handlePrintParticipants = () => {
    const printContents = participantListRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };


  const validateTableForm = () => {
    const errors = {};

    if (!newTable.nom) errors.nom = "Le nom de la table est requis.";
    if (!newTable.nombre_place) errors.nombre_place = "Le nombre de places est requis.";

    setTableErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const getAllTables = async () => {
    try {
      const response = await axios.get('https://apievent.obidoninternational.com/alltabs');
      if (response) {
        setListTable(response.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAllProgrammes = async () => {
    try {
      const response = await axios.get('https://apievent.obidoninternational.com/allprogrammes');
      if (response) {
        setProgramData(response.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAllInvites = async () => {
    try {
      const response = await axios.get('https://apievent.obidoninternational.com/allparticipants',);
      console.log(response)
      console.log(response.data)
      if (response) {
        setInviteData(response.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAllParticipants = async () => {
    try {
      const response = await axios.get('https://apievent.obidoninternational.com/allinvite');
      console.log(response)
      console.log(response.data)
      if (response) {
        setParticipantData(response.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllTables();
    getAllProgrammes();
    getAllInvites();
    getAllParticipants();
  }, []);
  console.log(participantData)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProgram({ ...newProgram, [name]: value });
  };

  const handleInputParticipantChange = (e) => {
    const { name, value } = e.target;
    setNewInvite({ ...newInvite, [name]: value });
  };

  const handleTableInputChange = (e) => {
    const { name, value } = e.target;
    setNewTable({ ...newTable, [name]: value });
  };

  const togglePrintModal = () => setPrintModalVisible(!printModalVisible);
  const toggleGuestsModal = () => setGuestsModalVisible(!guestsModalVisible);

  const handlePrintSubmit = async () => {
    try {
      const data = {
        id: selectedTableForPrint,
      };

      const response = await axios.post(`https://apievent.obidoninternational.com/invitebytable`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Assurez-vous que response.data est un tableau
      const guests = Array.isArray(response.data) ? response.data : [];

      // Vérification si la table ne contient aucun invité
      if (guests.length === 0) {
        setNoGuestsModalVisible(true);  // Affiche le modal si aucun invité n'est trouvé
      } else {
        setGuestsForSelectedTable(guests);
        toggleGuestsModal();
        togglePrintModal();
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite lors de la récupération des invités");
    }
  };

  const handleProgramSubmit = async () => {
    if (!validateProgramForm()) return;
    try {
      await axios.post('https://apievent.obidoninternational.com/createprogramme', newProgram, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Programme ajouté avec succès");
      setProgramData([...programData, newProgram]);
      setNewProgram({ libelle: '', heure_debut: '', heure_fin: '', localisation: '' });
      setFormVisible(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite");
    }
  };

  const handleTableSubmit = async () => {
    if (!validateTableForm()) return;
    try {
      const response = await axios.post(
        'https://apievent.obidoninternational.com/createtab',
        newTable,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 201) {
        toast.error("Ce nom de table est déjà pris. Veuillez choisir un autre nom.");
      } else {
        toast.success("Table ajoutée avec succès");

        setTables([...tables, response.data]); 
        setListTable([...listTable, newTable]);
        setNewTable({ nom: '', nombre_place: '' });
        setTableFormVisible(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite");
    }
  };
  

  const handleAcceptInvite = (index) => {
    setSelectedInvite(index);
    setSelectedTable('');
    toggleModal();
  };

  const handleEditTable = (table) => {

    setEditTable(table);
    setNewTable(table);
    setTableFormVisible(true);
    setEditMode(true);
  };
 
  console.log(listTable)

  const handleEditTableSubmit = async () => {
    try {
      console.log(newTable)
      await axios.post('https://apievent.obidoninternational.com/updatetab', newTable, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Table modifiée avec succès");

      setTables(tables.map((table) => (table.id === newTable.id ? newTable : table)));
      setListTable(listTable.map((table) => (table.id === newTable.id ? newTable : table)));
      setNewTable({ nom: '', nombre_place: '' });
      setTableFormVisible(false);
      setEditMode(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite");
    }
  };

  console.log(participantData);
  console.log(inviteData);

  const handleNameTableSubmit = async () => {
    try {
      await axios.post(
        'https://apievent.obidoninternational.com/createinvite',
        {
          id: inviteData[selectedInvite].id,
          id_tab: selectedTable
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      const selectedTableId = Number(selectedTable);
      const selectedTableData = listTable.filter(table => Number(table.id) === selectedTableId);
  
      const tableName = selectedTableData.length > 0 ? selectedTableData[0].nom : 'Unknown Table';
      const tablePlaces = selectedTableData.length > 0 ? selectedTableData[0].nombre_place : 'Unknown';
  
      const updatedParticipantData = [
        ...participantData,
        {
          nom: inviteData[selectedInvite].nom,
          Username: inviteData[selectedInvite].username,
          Table: tableName,
          Nombre_place: tablePlaces,
        },
      ];
  
      const updatedInviteData = inviteData.filter((_, index) => index !== selectedInvite);
      
      setParticipantData(updatedParticipantData);
      setInviteData(updatedInviteData);
      setNewInvite({ nom: '', Username: '', Table: '', Nombre_place: '' });
      setSelectedTable('');
      toggleModal();
  
      toast.success("Numéro de table assigné avec succès");
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite");
    }
  };
  
  
  
  
  
    const handleDeleteTable = async (id) => {
    try {
      const data = {
        id: id
      }
      console.log(data)
      await axios.post(`https://apievent.obidoninternational.com/deletetab`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      toast.success("Table supprimée avec succès");
      setTables(tables.filter(table => table.id !== id));
      setListTable(listTable.filter(table => table.id !== id));
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite");
    }
  };

  const handleEditProgram = (program) => {
    setEditProgram(program);
    setNewProgram(program);
    setFormVisible(true);
    setEditMode(true);
  };

  const handleDeleteProgram = async (id) => {
    try {
      const data = {
        id: id
      }
      console.log(data)
      const response = await axios.post(`https://apievent.obidoninternational.com/deleteprogram`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response.data)
      toast.success("Programme supprimé avec succès");
      setProgramData(programData.filter(program => program.id !== id));
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite");
    }
  };

  const handleEditProgramSubmit = async () => {
    try {
      await axios.post('https://apievent.obidoninternational.com/updateprogram', newProgram, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Programme modifié avec succès");

      // Met à jour la liste des programmes avec les nouvelles données
      setProgramData(programData.map((program) => (program.id === newProgram.id ? newProgram : program)));
      setNewProgram({ libelle: '', heure_debut: '', heure_fin: '' });
      setFormVisible(false);
      setEditMode(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur s'est produite");
    }
  };

  return (
    <div className="container" style={{ marginTop: '30px' }}>
      <ToastContainer closeButton={false} position="top-center" />
      <div className="d-flex mb-3">
        <Button style={{ backgroundColor: '#ab761e', whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={() => setFormVisible(!formVisible)} className="me-2 border-none">
          Créer un programme
        </Button>
        <Button style={{ backgroundColor: '#ab761e', whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={() => setTableFormVisible(!tableFormVisible)}>
          Créer une table
        </Button>
      </div>

      {formVisible && (
        <div className="border p-4 mb-4 position-relative">
          <button
            type="button"
            className="close position-absolute top-0 end-0 p-2"
            aria-label="Close"
            onClick={() => setFormVisible(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="libelle">Nom du programme</Label>
                  <Input
                    type="text"
                    name="libelle"
                    id="libelle"
                    value={newProgram.libelle}
                    onChange={handleInputChange}
                    className={`border-secondary ${programErrors.libelle ? 'is-invalid' : ''}`}
                    style={{ fontFamily: 'fantasy' }}
                  />
                  {programErrors.libelle && <div className="text-danger">{programErrors.libelle}</div>}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="heure_debut">Heure début</Label>
                  <Input
                    type="time"
                    name="heure_debut"
                    id="heure_debut"
                    value={newProgram.heure_debut}
                    onChange={handleInputChange}
                    className={`border-secondary ${programErrors.heure_debut ? 'is-invalid' : ''}`}
                  />
                  {programErrors.heure_debut && <div className="text-danger">{programErrors.heure_debut}</div>}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="heure_fin">Heure fin</Label>
                  <Input
                    type="time"
                    name="heure_fin"
                    id="heure_fin"
                    value={newProgram.heure_fin}
                    onChange={handleInputChange}
                    className={`border-secondary ${programErrors.heure_fin ? 'is-invalid' : ''}`}
                  />
                  {programErrors.heure_fin && <div className="text-danger">{programErrors.heure_fin}</div>}
                </FormGroup>
              </Col>
            </Row>
            <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={editMode ? handleEditProgramSubmit : handleProgramSubmit}>
              {editMode ? "Enregistrer" : "Ajouter"}
            </Button>
          </Form>

        </div>
      )}

      {tableFormVisible && (
        <div className="border p-4 mb-4 position-relative">
          <button
            type="button"
            className="close position-absolute top-0 end-0 p-2"
            aria-label="Close"
            onClick={() => setTableFormVisible(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="nom">Nom de la table</Label>
                  <Input
                    type="text"
                    name="nom"
                    id="nom"
                    value={newTable.nom}
                    onChange={handleTableInputChange}
                    className={`border-secondary ${tableErrors.nom ? 'is-invalid' : ''}`}
                  />
                  {tableErrors.nom && <div className="text-danger">{tableErrors.nom}</div>}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="nombre_place">Nombre de places</Label>
                  <Input
                    type="number"
                    name="nombre_place"
                    id="nombre_place"
                    value={newTable.nombre_place}
                    onChange={handleTableInputChange}
                    className={`border-secondary ${tableErrors.nombre_place ? 'is-invalid' : ''}`}
                  />
                  {tableErrors.nombre_place && <div className="text-danger">{tableErrors.nombre_place}</div>}
                </FormGroup>
              </Col>
            </Row>
            <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={editMode ? handleEditTableSubmit : handleTableSubmit}>
              {editMode ? "Enregistrer" : "Ajouter"}
            </Button>
          </Form>

        </div>
      )}
      <Modal isOpen={printModalVisible} toggle={togglePrintModal}>
        <ModalHeader toggle={togglePrintModal}>Sélectionner une table pour imprimer la liste des invités</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="tableSelectForPrint">Sélectionner une table</Label>
            <Input
              type="select"
              name="table"
              id="tableSelectForPrint"
              value={selectedTableForPrint}
              onChange={(e) => setSelectedTableForPrint(e.target.value)}
            >
              <option value="">Sélectionner une table</option>
              {Array.isArray(listTable) && listTable.map((table, index) => (
                <option key={index} value={table.id}>
                  {table.nom}
                </option>
              ))}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={handlePrintSubmit}>
            Valider
          </Button>
          <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={togglePrintModal}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={noGuestsModalVisible} toggle={() => setNoGuestsModalVisible(false)}>
        <ModalHeader toggle={() => setNoGuestsModalVisible(false)}>Avertissement</ModalHeader>
        <ModalBody>
          Cette table ne contient aucun invité.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setNoGuestsModalVisible(false)}>Fermer</Button>
        </ModalFooter>
      </Modal>



      <Modal isOpen={guestsModalVisible} toggle={toggleGuestsModal} innerRef={guestsModalRef}>
        <ModalHeader toggle={toggleGuestsModal}>
          Liste des invités pour la table sélectionnée
        </ModalHeader>
        <ModalBody>
          <Table className="table table-striped table-hover">
            <thead>
              <tr>
                <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>#</th>
                <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>Nom et Prénoms</th>
                <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>Table</th>
              </tr>
            </thead>
            <tbody>
              {guestsForSelectedTable.map((guest, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{guest.nom}</td>
                  <td>{guest.Table}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }}
            onClick={toggleGuestsModal}
          >
            Annuler
          </Button>
          <Button 
          style={buttonStyles}
          onClick={() => handleButtonClick(handlePrintGuestsModal)}
        >
          Imprimer la liste
        </Button>
        </ModalFooter>
      </Modal>


      <h2>Liste des programmes</h2>
      <div className="table-responsive">
        <Table className="table table-striped table-hover">
          <thead>
            <tr>
              <th className="text-white" scope="col" style={{ backgroundColor: 'blue' }}>#</th>
              <th className="text-white" scope="col" style={{ backgroundColor: 'blue' }}>Libellé</th>
              <th className="text-white" scope="col" style={{ backgroundColor: 'blue' }}>Heure de début</th>
              <th className="text-white" scope="col" style={{ backgroundColor: 'blue' }}>Heure de fin</th>
              <th className="text-white" scope="col" style={{ backgroundColor: 'blue' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {programData.map((program, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{program.libelle}</td>
                <td>{program.heure_debut}</td>
                <td>{program.heure_fin}</td>
                <td>
                  <Link to="#" className="text-truncate me-4" onClick={() => handleEditProgram(program)}>
                    <UilPen size="15" className="text-top" id="edittooltip" />
                  </Link>
                  <Link to="#" className="text-truncate me-4 text-danger" onClick={() => handleDeleteProgram(program.id)}>
                    <UilTrashAlt size="15" className="text-top" id="deletetooltip" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <h2>Liste des tables</h2>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th className="text-white" scope="col" style={{ backgroundColor: 'blue' }}>Nom de la table</th>
            <th className="text-white" scope="col" style={{ backgroundColor: 'blue' }}>Nombre de places</th>
            <th className="text-white" scope="col" style={{ backgroundColor: 'blue' }}>Actions</th>
          </tr>
        </thead>

        <tbody>
          {Array.isArray(listTable) && listTable.map((table, index) => (
            <tr key={index}>
              <td>{table.nom}</td>
              <td>{table.nombre_place}</td>
              <td>
                <Link to="#" className="text-truncate me-4" onClick={() => handleEditTable(table)}>
                  <UilPen size="15" className="text-top" id="edittooltip" />
                </Link>
                <Link to="#" className="text-truncate me-4 text-danger" onClick={() => handleDeleteTable(table.id)}>
                  <UilTrashAlt size="15" className="text-top" id="deletetooltip" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h2>Liste des participants</h2>
      <div className="row">
      <div className="col">
        <Table className="table-striped table-hover" style={tableStyles}>
          <thead>
            <tr>
              <th style={{ ...headerStyle, ...cellStyles }} scope="col">#</th>
              <th style={{ ...headerStyle, ...cellStyles }} scope="col">Nom et Prénoms</th>
              <th style={{ ...headerStyle, ...cellStyles }} scope="col">Identifiant</th>
              <th style={{ ...headerStyle, ...cellStyles }} scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {inviteData.map((invite, index) => (
              <tr key={index}>
                <th style={cellStyles} scope="row">{index + 1}</th>
                <td style={cellStyles}>{invite.nom}</td>
                <td style={cellStyles}>{invite.username}</td>
                <td style={cellStyles}>
                  <Button
                    color="success"
                    className="text-nowrap border-0 rounded-0"
                    onClick={() => handleAcceptInvite(index)}
                  >
                    Accepter
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>

      <h2>Liste des invités</h2>
      <div ref={participantListRef}>
        <Table className="table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>#</th>
              <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>Nom et Prénoms</th>
              <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>Identifiant</th>
              <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>Table</th>
              <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>Nombre de places</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(participantData) && participantData.map((participant, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{participant.nom}</td>
                <td>{participant.Username}</td>
                <td>{participant.Table}</td>
                <td>{participant.Nombre_place}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-end">
      <Button
          style={buttonStyles}
          onClick={() => handleButtonClick(handlePrintParticipants)}
        >
          Imprimer la liste des invités
        </Button>
      </div>

      <h2>Liste des invités par table</h2>
      <Table className="table-responsive table-striped table-hover">
        <thead>
          <tr>
            <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>#</th>
            <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>Nom et Prénoms</th>
            <th className='text-white' scope="col" style={{ backgroundColor: 'blue' }}>Table</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(participantData) && participantData.map((participant, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{participant.nom}</td>
              <td>{participant.Table}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
      <Button
          style={{ width: windowWidth < 768 ? '20%' : 'auto',     backgroundColor: "#ab761e",
            whiteSpace: 'nowrap',
            border: 'none',
            borderRadius: '0',}}
          onClick={() => handleButtonClick(togglePrintModal)}
        >
          Imprimer
        </Button>
      </div>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Assigner une table à l'invité</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="tableSelect">Sélectionner une table</Label>
            <Input
              type="select"
              name="table"
              id="tableSelect"
              value={selectedTable}
              onChange={(e) => setSelectedTable(e.target.value)}
            >
              <option value="">Sélectionner une table</option>
              {Array.isArray(listTable) && listTable.map((table, index) => (
                <option key={index} value={table.id}>
                  {table.nom}
                </option>
              ))}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={handleNameTableSubmit} onChange={handleInputParticipantChange}>Assigner</Button>{' '}
          <Button style={{ backgroundColor: "#ab761e", whiteSpace: 'nowrap', border: 'none', borderRadius: '0' }} onClick={toggleModal}>Annuler</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProgramPage;
